<template>
  <ion-slides ref="slides" class="slides-cate" :options="slideOpts" v-if="listData?.length > 0">
    <ion-slide v-for="cate in listData" :key="cate.id" @click="chooseCate(cate.id)">
      <div class="d-flex flex-column align-center">
        <div class="slide-item" :class="cate.id == selectedCategory ? 'active' : ''">
          <ion-img
            v-if="!cate.useIcon && categoryPhoto[cate.name]"
            class="ion-img"
            :src="categoryPhoto[cate.name]"
            @ionError="errorImage"
            :color="cate.id == selectedCategory ? 'light' : 'grey'"
            size="small"
          ></ion-img>
          <ion-icon
            v-if="cate.useIcon && !cate.useSrc"
            :icon="cate.icon"
            :color="cate.id == selectedCategory ? 'light' : 'grey'"
          ></ion-icon>
          <ion-icon
            v-if="cate.useIcon && cate.useSrc"
            :src="showIcon(cate, selectedCategory)"
            :color="cate.id == selectedCategory ? 'light' : 'grey'"
          ></ion-icon>
        </div>
        <ion-text class="mt-1 fw-normal" :color="cate.id == selectedCategory ? 'primary' : 'grey'">
          {{ cate.name }}
        </ion-text>
      </div>
    </ion-slide>
  </ion-slides>
</template>
<script>
import noImage from '@/assets/images/no_image.svg';
import bookingsActive from '@/assets/images/sale/home/bookings_white.svg';
import historyActive from '@/assets/images/sale/home/history_white.svg';
import { useUpdateFilter } from '@/components/sort/composable';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { listCates } from '@/modules/sale/constants';
import { ACTIONS } from '@/modules/sale/store/category/actions';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/sale/store/product/actions';
import { getImage } from '@/utils/';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/category');
const { mapActions: mapActionsProduct, mapGetters: mapGettersProduct } = createNamespacedHelpers(
  'sale/product'
);

export default defineComponent({
  name: 'Slide cates',

  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };
    const { onSetDefaultSort } = useUpdateFilter();
    const showIcon = (cate, selectedCate) => {
      let cateName = cate.name;
      let cateId = cate.id;
      return cateName === 'History' && cateId === selectedCate
        ? historyActive
        : cateName === 'Bookings' && cateId === selectedCate
        ? bookingsActive
        : cate.image;
    };

    return {
      slideOpts,
      onSetDefaultSort,
      noImage,
      historyActive,
      bookingsActive,
      showIcon
    };
  },

  async mounted() {
    await this[ACTIONS.GET_CATEGORIES]();
    for (const cat of this.list) {
      this.categoryPhoto[cat.name] = await getImage(cat.name, IMAGE_PATH.CATEGORY_ICON);
    }
    this.listData = [...this.listCates, ...this.list];
    this.selectedCategory = this.search.mainCategoryId;
  },

  computed: {
    ...mapGetters(['list', 'loading']),
    ...mapGettersProduct(['search', 'listProduct']),
    ...mapGettersProduct({
      productLoading: 'loading',
      productList: 'list'
    })
  },

  data() {
    return {
      listCates,
      listData: [],
      categoryPhoto: {},
      selectedCategory: ''
    };
  },

  emits: ['reset-search', 'setExploreProduct'],

  methods: {
    ...mapActions([ACTIONS.GET_CATEGORIES]),
    ...mapActionsProduct([ACTIONS_PRODUCT.UPDATE_SEARCH]),

    chooseCate(id) {
      if (!this.productLoading) {
        this.selectedCategory = `${id}`;
        this.onSetDefaultSort(`${id}`);
        this.$emit('reset-search', { cate: `${id}`, checkCate: id + '-' });
        this.$emit('setExploreProduct');
      }
    },
    isActive(cateId) {
      return this.search.mainCategoryId == cateId;
    },
    errorImage(event) {
      event.target.src = noImage;
    }
  },
  watch: {
    async search() {
      this.selectedCategory = this.search.mainCategoryId;

      // slide to beginning
      if (this.$refs.slides) {
        const ITEM_PER_FRAME = 5;
        let currentIdx = this.listData.findIndex((opt) => opt.id == this.selectedCategory);
        const activeIndex = await this.$refs.slides.$el.getActiveIndex();
        if (currentIdx > activeIndex && activeIndex + ITEM_PER_FRAME >= currentIdx) {
          currentIdx = activeIndex;
        }
        currentIdx =
          currentIdx > this.listData.length - ITEM_PER_FRAME ? this.listData.length - 5 : currentIdx;
        this.$refs.slides.$el.slideTo(currentIdx, 500);
      }
    },
    $route() {
      if (this.$router.currentRoute.value.params.isNewest) {
        this.chooseCate('new');
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.slide-item {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background-color: var(--ion-color-primary);
}

.slides-cate {
  overflow: auto;
  margin-bottom: 1rem;
}

.slides-cate::-webkit-scrollbar {
  display: none;
}

ion-icon {
  font-size: 25px;
}

ion-img::part(image) {
  width: 25px;
  height: 25px;
}

ion-text {
  font-size: 14px;
}

ion-slide {
  width: initial;
  margin: 0 10px;
}
</style>
